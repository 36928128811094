import { defineComponent, toRefs, reactive, computed, defineAsyncComponent } from 'vue';
import Eldialog from '@/components/Eldialog/index.vue';
import { ElSwitch } from 'element-plus';
export default defineComponent({
  name: "FamilyBinding",
  components: {
    Eldialog,
    ElSwitch,
    TreeSelect: defineAsyncComponent(() => import('@/components/TreeSelect/TreeSelect.vue'))
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {
    emit
  }) {
    const refData = reactive({
      title: '亲情号码绑定',
      info: [{
        name: '家长账号：',
        value: '蒋明辉',
        key: ''
      }, {
        name: '绑定时间：',
        value: '2021-07-02 16:09:28',
        key: ''
      }, {
        name: '绑定关系：',
        value: '家庭成员',
        key: ''
      }]
    });
    // 初始化表单
    const show = computed({
      get: () => props.visible,
      set: val => {
        emit('update:visible', val);
      }
    });
    // 结构树回调
    const handleClick = data => {
      console.log(data);
    };
    // 确定按钮
    const confirm = () => {
      emit('update:visible', false);
    };
    return {
      ...toRefs(refData),
      show,
      handleClick,
      confirm
    };
  }
});