import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1c175e36"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "content"
};
const _hoisted_2 = {
  class: "content_left"
};
const _hoisted_3 = {
  class: "name"
};
const _hoisted_4 = {
  class: "value"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Eldialog = _resolveComponent("Eldialog");
  return _openBlock(), _createBlock(_component_Eldialog, _mergeProps(_ctx.$attrs, {
    visible: _ctx.show,
    "onUpdate:visible": _cache[0] || (_cache[0] = $event => _ctx.show = $event),
    title: _ctx.title,
    width: "360px",
    onConfirmOk: _ctx.confirm
  }), {
    form: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.info, item => {
      return _openBlock(), _createElementBlock("p", {
        key: item.name
      }, [_createElementVNode("span", _hoisted_3, _toDisplayString(item.name), 1), _createElementVNode("span", _hoisted_4, _toDisplayString(item.value), 1)]);
    }), 128))])])])]),
    _: 1
  }, 16, ["visible", "title", "onConfirmOk"]);
}